import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button } from '@mui/material';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData, fetchData2 } from 'src/fetch';
import {
  TextField,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  TextareaAutosize,
  Switch,
  Slider,
  Box,
} from '@mui/material';
import { cafStatusList, aspStatusList, herStatusList } from '../statusHelper';
import Dropdown from '../../../../../components/Dropdown';

export const services = [
  { value: 'legalentityfounding', label: 'Cégalapítás' },
  { value: 'taxconsultingsimple', label: 'CAF' },
  { value: 'taxconsultingcomplex', label: 'Komplex adótanácsadás' },
  { value: 'accounting', label: 'Könyvelés' },
  { value: 'payroll', label: 'Bérszámfejtés' },
  { value: 'taxconsulting', label: 'Folyamatos adótanácsadás' },
];

const StatusValidation = ({ statusID }) => {
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;
  const [message, setMessage] = useState('');
  const [callNote, setCallNote] = useState('');
  const [requestData, setRequestData] = useState();
  const [tempData, setTempData] = useState();
  const [tempStatus, setTempStatus] = useState();
  const [matchScore, setMatchScore] = useState();
  const [updatedProvides, setUpdatedProviders] = useState();
  const [discountObj, setDiscountObj] = useState();
  const [tempObj, setTempObj] = useState({
    chosendate: '',
    startingdate: 0,
    accounting: false,
    taxconsulting: false,
    taxconsultingsimple: false,
    taxconsultingcomplex: false,
    legalentityfounding: false,
    payroll: false,
    audit: false,
    liquidation: false,
    languagefilter: false,
    languages: {
      english: false,
      german: false,
      spanish: false,
      french: false,
      romanian: false,
      chinese: false,
      russian: false,
    },
    noofemployees: 0,
    yearlyrevenue: 0,
    incominginvoices: 0,
    outgoinginvoices: 0,
    industry: '',
    companytype: '',
    companyctcname: '',
    zip_code: '',
    foreign_trade: false,
    foreigntradeEU: '',
    newlyfounded: false,
    vatFilter: '',
    vatPayment: '',
    taxform: 'idk',
    email: '',
    gdpr: false,
    marketing: false,
    reqMessage: '',
    companyPhone: '',
    onlyBrowsing: false,
    isDiscounted: false,
    discountCode: '',
    bankingpref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      other: false,
      bankidk: false,
    },
    invoicingpref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      other: false,
      invoicingidk: false,
    },
    softwarepref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      opt9: false,
      opt10: false,
      opt11: false,
      opt12: false,
      opt13: false,
      opt14: false,
      opt15: false,
      opt16: false,
      opt17: false,
      opt18: false,
      opt19: false,
      opt20: false,
      other: false,
    },
    multiplecompany: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    utm_term: '',
    gclid: '',
    dclid: '',
    industryinput: '',
    proposedSinglePrice: '',
    proposedContPrice: '',
    originalSinglePrice: '',
    originalContPrice: '',
    statuslog: [],
    responsibleagent: '',
    chosenprovider: '',
    chosenlawyer: '',
    howdidyoufindus: '',
    howdidyoufindusinput: '',
    whychangingaccountant: '',
    messagelog: [],
    isfakecherry: false,
    interestedInBupa: false,
    whereElseDidSearch: [],
    whatCanWeImprove: '',
    howHardWasIt: '',
    howHardNr: 0,
    whyNotUs: '',
    whyNotUsText: '',
    companylegalname: '',
    selectedProvGrp: '',
  });
  const [servicesPicked, setServicesPicked] = useState([]);
  const [placesSearched, setPlacesSearched] = useState([]);
  const [languagesPicked, setLanguagesPicked] = useState([]);
  const [chosenBank, setChosenBank] = useState([]);
  const [chosenInvo, setChosenInvo] = useState([]);
  const [chosenSoft, setChosenSoft] = useState([]);
  const [usedDiscount, setUsedDiscount] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const [selectedGrp, setSelectedGrp] = useState(null);

  const bankPref = [
    { value: 'opt1', label: 'OTP' },
    { value: 'opt2', label: 'Erste' },
    { value: 'opt3', label: 'MBH (BB, MKB, Takarék)' },
    { value: 'opt4', label: 'Raiffeisen' },
    { value: 'opt5', label: 'UniCredit' },
    { value: 'opt6', label: 'K&H' },
    { value: 'opt7', label: 'Magnet' },
    { value: 'opt8', label: 'CIB' },
    { value: 'other', label: 'Egyéb' },
    { value: 'bankidk', label: 'Még nem tudom' },
  ];
  const invoPref = [
    { value: 'opt1', label: 'Számlázz.hu' },
    { value: 'opt2', label: 'Billingo' },
    { value: 'opt3', label: 'Billcity' },
    { value: 'opt4', label: 'Billsoft' },
    { value: 'opt5', label: 'SAP' },
    { value: 'opt6', label: 'NAV Online Számla' },
    { value: 'opt7', label: 'BUPA' },
    { value: 'other', label: 'Egyéb' },
    { value: 'invoicingidk', label: 'Még nem tudom' },
  ];
  const softPref = [
    { value: 'opt1', label: 'Apollo' },
    { value: 'opt2', label: 'Cobra' },
    { value: 'opt3', label: 'ComboSoft' },
    { value: 'opt4', label: 'Forint-Soft' },
    { value: 'opt5', label: 'Hessyn' },
    { value: 'opt6', label: 'IMA' },
    { value: 'opt7', label: 'Infotéka' },
    { value: 'opt8', label: 'Kulcs-Szoft' },
    { value: 'opt9', label: 'Libra' },
    { value: 'opt10', label: 'Makrodigit' },
    { value: 'opt11', label: 'Maxi-Tax' },
    { value: 'opt12', label: 'Nagy Machinátor' },
    { value: 'opt13', label: 'Novitax' },
    { value: 'opt14', label: 'QualitySoft' },
    { value: 'opt15', label: 'Relax' },
    { value: 'opt16', label: 'RLB' },
    { value: 'opt17', label: 'SAP' },
    { value: 'opt18', label: 'Smartbooks' },
    { value: 'opt19', label: 'TenSoft' },
    { value: 'opt20', label: 'Webkönyvelő' },
    { value: 'other', label: 'Egyéb' },
  ];

  const whereDidSearch = [
    { value: 'google', label: 'Google' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'tiktok', label: 'Tik-tok' },
    { value: 'friend', label: 'Ismerős' },
    { value: 'bupa', label: 'BUPA' },
    { value: 'szamlazzhu', label: 'Számlázz.hu' },
    { value: 'freelancerblog', label: 'Freelancerblog' },
    { value: 'glamour', label: 'Glamour' },
    { value: 'otherweb', label: 'Más weboldal' },
    { value: 'bookkeepieblog', label: 'Bookkeepie Blog' },
    { value: 'other', label: 'Egyéb' },
    { value: 'idk', label: 'Nem emlékszik' },
  ];

  const languages = ['english', 'german', 'french', 'spanish', 'romanian', 'chinese', 'russian'];

  const sliderMarks = [
    { value: 1, label: 'könnyű' },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5, label: 'nehéz' },
  ];

  const updateCallLog = async (reqID, newMessage) => {
    await fetchData(`${baseUrl}/api/aspreq/calllog/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newMessage,
    })
      .then(async (response) => {
        let resp = await response.json();
        reloadData();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  //  console.log(tempData, 'tempData');

  // const getStatusData = useCallback(async () => {
  //   await fetchData(`${baseUrl}/api/aspreq/getstatus/${statusID}`, 'GET', localStorage.getItem('JWT'))
  //     .then(async (response) => {
  //       let resp = await response.json();
  //       //console.log(resp, 'RESP');

  //       setTempStatus(resp.reqstatus);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert(err);
  //     });
  // }, [baseUrl, statusID]);

  // useEffect(() => {
  //   getStatusData();
  // }, [getStatusData]);

  const updateStatusStatus = async (reqID, newValue) => {
    await fetchData(`${baseUrl}/api/aspreq/status/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newValue,
    })
      .then(async (response) => {
        let resp = await response.json();

        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  // const reloadStatusData = async () => {
  //   await getStatusData();
  // };

  const couponCheck = useCallback(async (data) => {
    await fetchData(`${baseUrl}/api/coupons/get`, 'POST', localStorage.getItem('JWT'), data)
      .then(async (response) => {
        let resp = await response.json();
        setDiscountObj(resp);
        if (tempData.companytype === 'singleprimary' || 'singlesecondary' || 'kiva' || 'vszja') {
          setUsedDiscount(resp.discount);
          console.log('kicsi');
        } else {
          setUsedDiscount(resp.discountLarge);
          console.log('nagy');
        }
        if (tempData.originalContPrice === tempData.proposedContPrice) {
          console.log('egyforma ar', tempData);
          // if (tempData.selectedProvGrp !== '') {
          if (
            tempData.companytype === 'singleprimary' ||
            tempData.companytype === 'singlesecondary' ||
            tempData.companytype === 'kiva' ||
            tempData.companytype === 'vszja'
          ) {
            let newvalue;
            if (tempData.selectedProvGrp === 'ev') {
              newvalue = `${parseInt(tempData.potential_providers[0][0].contMinPrice) - resp.discount} - ${
                parseInt(tempData.potential_providers[0][0].contMaxPrice) - resp.discount
              }`;
            } else if (tempData.selectedProvGrp === 'office') {
              newvalue = `${parseInt(tempData.potential_providers[1][0].contMinPrice) - resp.discount} - ${
                parseInt(tempData.potential_providers[1][0].contMaxPrice) - resp.discount
              }`;
            } else if (tempData.selectedProvGrp === 'enterprise') {
              newvalue = `${parseInt(tempData.potential_providers[2][0].contMinPrice) - resp.discount} - ${
                parseInt(tempData.potential_providers[2][0].contMaxPrice) - resp.discount
              }`;
            }
            console.log(newvalue, 'NYUVELJU SZMOLL');
            setTempData((prevState) => ({
              ...prevState,
              proposedContPrice: newvalue,
            }));
            setIsChanged(true);
          } else {
            let newvalue;
            if (tempData.selectedProvGrp === 'ev') {
              newvalue = `${parseInt(tempData.potential_providers[0][0].contMinPrice) - resp.discountLarge} - ${
                parseInt(tempData.potential_providers[0][0].contMaxPrice) - resp.discountLarge
              }`;
            } else if (tempData.selectedProvGrp === 'office') {
              newvalue = `${parseInt(tempData.potential_providers[1][0].contMinPrice) - resp.discountLarge} - ${
                parseInt(tempData.potential_providers[1][0].contMaxPrice) - resp.discountLarge
              }`;
            } else if (tempData.selectedProvGrp === 'enterprise') {
              newvalue = `${parseInt(tempData.potential_providers[2][0].contMinPrice) - resp.discountLarge} - ${
                parseInt(tempData.potential_providers[2][0].contMaxPrice) - resp.discountLarge
              }`;
            }
            // console.log(newvalue, 'NYUVELJU LARDZS');
            setTempData((prevState) => ({
              ...prevState,
              proposedContPrice: newvalue,
            }));
            setIsChanged(true);
          }
          // } else {
          //   if (
          //     tempData.companytype === 'singleprimary' ||
          //     tempData.companytype === 'singlesecondary' ||
          //     tempData.companytype === 'kiva' ||
          //     tempData.companytype === 'vszja'
          //   ) {
          //     let newvalue = parseInt(tempObj.originalContPrice.split('.').join('')) - resp.discount;
          //     //console.log(newvalue, 'NYUVELJU SZMOLL');
          //     setTempData((prevState) => ({
          //       ...prevState,
          //       proposedContPrice: newvalue,
          //     }));
          //     setIsChanged(true);
          //   } else {
          //     let newvalue = parseInt(tempObj.originalContPrice.split('.').join('')) - resp.discountLarge;
          //     // console.log(newvalue, 'NYUVELJU LARDZS');
          //     setTempData((prevState) => ({
          //       ...prevState,
          //       proposedContPrice: newvalue,
          //     }));
          //     setIsChanged(true);
          //   }
          // }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const getRequestData = useCallback(
    async (statusID) => {
      await fetchData(`${baseUrl}/api/aspreq/${statusID}`, 'GET', localStorage.getItem('JWT'))
        .then(async (response) => {
          let resp = await response.json();
          //console.log(resp, 'RESP');
          setRequestData(resp);
          setTempData(resp);
          console.log(resp, "RESPONSE");
          setTempStatus(resp.reqstatus);
          setServicesPicked(resp.services);
          if (resp.selectedProvGrp) {
            console.log('SELECTED GRP', resp.selectedProvGrp);
            setSelectedGrp(resp.selectedProvGrp);
          }
          if (resp.discountCode) {
            resp.discountCode &&
            (await couponCheck({
              couponCode: resp.discountCode,
              flow: 'search',
            }));
          }
          tempData && matchObjects(tempObj, tempData);
          if (resp.languages !== null && resp.languages !== undefined) {
            let newLangs = [];
            for (const [key, value] of Object.entries(resp.languages)) {
              if (value === true) {
                newLangs.push(key);
              }
            }
            newLangs.length !== 0 && setLanguagesPicked(newLangs);
            setPlacesSearched(resp.whereElseDidSearch);
          }
          if ((resp.bankingpref !== null || resp.bankingpref !== undefined) && resp.bankingpref) {
            let newBanks = [];
            for (const [key, value] of Object.entries(resp.bankingpref)) {
              if (value === true) {
                newBanks.push(key);
              }
            }
            setChosenBank(newBanks);
          }
          if ((resp.invoicingpref !== null || resp.invoicingpref !== undefined) && resp.invoicingpref) {
            let newInvo = [];
            for (const [key, value] of Object.entries(resp.invoicingpref)) {
              if (value === true) {
                newInvo.push(key);
              }
            }
            setChosenInvo(newInvo);
          }
          if ((resp.softwarepref !== null || resp.softwarepref !== undefined) && resp.softwarepref) {
            let newSoft = [];
            for (const [key, value] of Object.entries(resp.softwarepref)) {
              if (value === true) {
                newSoft.push(key);
              }
            }
            setChosenSoft(newSoft);
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    },
    [baseUrl, statusID]
  );

  //console.log(tempData, 'TEMPDATA');

  useEffect(() => {
    //getStatusData();
    getRequestData(statusID);
  }, []);

  const reloadData = async () => {
    await getRequestData(statusID);
  };

  const matchObjects = useCallback(
    (targetObj, sourceObj) => {
      for (const [targetObjkey, targetObjvalue] of Object.entries(targetObj)) {
        for (const [sourceObjkey, sourceObjvalue] of Object.entries(sourceObj)) {
          if (targetObjkey === sourceObjkey) {
            setTempObj((prevState) => ({ ...prevState, [targetObjkey]: sourceObjvalue }));
          }
          if (sourceObjkey === 'services') {
            sourceObjvalue.forEach((serv) => {
              setTempObj((prevState) => ({ ...prevState, [serv]: true }));
            });
          }
        }
      }
    },
    [tempData]
  );

  const setMatchScoreObject = useCallback(() => {
    let splitStrings = [];
    let splitMatches = [];
    let mSObj = {
      distanceWeight: 1,
      priceWeight: 1,
      reviewWeight: 1,
      experienceWeight: 1,
      liabilityInsWeight: 1,
      badgeWeight: 1,
      phoneWeight: 1,
      availabilityWeight: 1,
    };
    typeof tempData.preferences === 'string' && tempData.preferences.split(',').forEach((e) => splitMatches.push(e));

    splitMatches.forEach((e) => splitStrings.push(e.split(':')));
    for (const rows in splitStrings) {
      if (splitStrings[rows][0].trim() === 'közelség') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.distanceWeight = '1';
        } else {
          mSObj.distanceWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'iparági tapasztalat') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.experienceWeight = '1';
        } else {
          mSObj.experienceWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'értékelés') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.reviewWeight = '1';
        } else {
          mSObj.reviewWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'biztosítás') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.liabilityInsWeight = '1';
        } else {
          mSObj.liabilityInsWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'ár') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.priceWeight = '1';
        } else {
          mSObj.priceWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'digitalizáltság') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.badgeWeight = '1';
        } else {
          mSObj.badgeWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'telefonos kapcs') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.phoneWeight = '1';
        } else {
          mSObj.phoneWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'folyamatos elérhetőség') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.availabilityWeight = '1';
        } else {
          mSObj.availabilityWeight = parseInt(splitStrings[rows][1]);
        }
      }
    }
    //console.log(mSObj, 'mSObjmSObjmSObjmSObj');
    setMatchScore(mSObj);
  });

  const callHandler = useCallback(
    (event) => {
      setCallNote(event.target.value);
    },
    [callNote, setCallNote]
  );

  useEffect(() => {
    tempData && setMatchScoreObject();
    //console.log(matchScore, 'MS OBJ');
  }, [tempData, tempObj]);

  const updateRequestData = useCallback(
    async (statusID, updateParams) => {
      await fetchData2(`${baseUrl}/api/aspreq/${statusID}`, 'PATCH', 'B3OeO1K7-KcEcE8PdI4Eb', {
        requestId: statusID,
        updateParams: updateParams,
      })
        .then(async (response) => {
          console.log('AXIOS RESPONSE: ', response);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    },
    [baseUrl, statusID]
  );

  const sendMail = async (tempObj) => {
    await fetchData(`${baseUrl}/api/aspreq/send/mail`, 'post', localStorage.getItem('JWT'), tempObj)
      .then(async (response) => {
        let resp = await response.json();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const updateOffer = async (tempObj, matchScore) => {
    const company_req = {
      company_req: tempObj,
      match_score_weights: matchScore,
    };
    //console.log(company_req, 'KOMPANI REQ');
    // if (
    //   tempData.selectedProvGrp === 'ev' ||
    //   tempData.selectedProvGrp === 'office' ||
    //   tempData.selectedProvGrp === 'enterprise'
    // ) {
    await fetchData2(`${baseUrl}/api/providerofferstop3/`, 'post', 'B3OeO1K7-KcEcE8PdI4Eb', company_req)
      .then(async (response) => {
        let resp = await response.json();
        console.log(resp, 'RESPONSE IN UPDATE');
        setUpdatedProviders(resp.matchingProviders);
        setTempObj((prevState) => ({
          ...prevState,
          potential_providers: resp.matchingProviders,
        }));
        setTempData((prevState) => ({
          ...prevState,
          potential_providers: resp.matchingProviders,
        }));
        if (tempData.selectedProvGrp === 'ev') {
          console.log('EV IN USEEFFECT');
          setTempObj((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[0][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[0][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[0][0].contMinPrice} - ${resp.matchingProviders[0][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[0][0].singleEndPrice,
          }));
          setTempData((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[0][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[0][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[0][0].contMinPrice} - ${resp.matchingProviders[0][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[0][0].singleEndPrice,
          }));
        } else if (tempData.selectedProvGrp === 'office') {
          console.log('OFFICE IN USEEFFECT');
          setTempObj((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[1][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[1][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[1][0].contMinPrice} - ${resp.matchingProviders[1][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[1][0].singleEndPrice,
          }));
          setTempData((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[1][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[1][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[1][0].contMinPrice} - ${resp.matchingProviders[1][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[1][0].singleEndPrice,
          }));
        } else if (tempData.selectedProvGrp === 'enterprise') {
          console.log('ENTERPRISE IN USEEFFECT');
          setTempObj((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[2][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[2][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[2][0].contMinPrice} - ${resp.matchingProviders[2][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[2][0].singleEndPrice,
          }));
          setTempData((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[2][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[2][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[2][0].contMinPrice} - ${resp.matchingProviders[2][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[2][0].singleEndPrice,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
    // } else {
    //   await fetchData2(`${baseUrl}/api/provideroffers/`, 'post', 'B3OeO1K7-KcEcE8PdI4Eb', company_req)
    //     .then(async (response) => {
    //       let resp = await response.json();
    //       //console.log(resp, 'RESPONSE IN UPDATE');
    //       setUpdatedProviders(resp.matchingProviders);

    //       setTempObj((prevState) => ({
    //         ...prevState,
    //         potential_providers: resp.matchingProviders,
    //         proposedContPrice: resp.matchingProviders[0].contEndPrice - (usedDiscount || 0),
    //         originalContPrice: resp.matchingProviders[0].contEndPrice,
    //         originalSinglePrice: resp.matchingProviders[0].singleEndPrice,
    //       }));
    //       setTempData((prevState) => ({
    //         ...prevState,
    //         potential_providers: resp.matchingProviders,
    //         originalContPrice: resp.matchingProviders[0].contEndPrice,
    //         originalSinglePrice: resp.matchingProviders[0].singleEndPrice,
    //         proposedContPrice: resp.matchingProviders[0].contEndPrice - (usedDiscount || 0),
    //       }));
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       alert(err);
    //     });
    // }
  };

  const updateStatusMessage = async (reqID, newMessage) => {
    await fetchData(`${baseUrl}/api/aspreq/message/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newMessage,
    })
      .then(async (response) => {
        let resp = await response.json();
        reloadData();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const serviceChange = (event) => {
    let serv = [];

    services.forEach((service) => {
      setTempObj((prevState) => ({ ...prevState, [service.value]: false, services: serv }));
      setTempData((prevState) => ({ ...prevState, [service.value]: false }));
    });

    const {
      target: { value },
    } = event;
    value.forEach((service) => {
      setTempObj((prevState) => ({ ...prevState, [service.value]: true }));
      setTempData((prevState) => ({ ...prevState, [service.value]: true }));
    });
    setServicesPicked(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setTempObj((prevState) => ({ ...prevState, services: servicesPicked }));
    setTempData((prevState) => ({ ...prevState, services: servicesPicked }));
  };
  const whereDidSearchChange = (event) => {
    const {
      target: { value },
    } = event;

    setPlacesSearched(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setTempObj((prevState) => ({ ...prevState, whereElseDidSearch: placesSearched }));
    setTempData((prevState) => ({ ...prevState, whereElseDidSearch: placesSearched }));
    setIsChanged(true);
    console.log(tempObj, 'TEMP');
  };

  useEffect(() => {
    if (placesSearched.length > 0) {
      setTempObj((prevState) => ({ ...prevState, whereElseDidSearch: placesSearched }));
      setTempData((prevState) => ({ ...prevState, whereElseDidSearch: placesSearched }));
    }
  }, [placesSearched]);

  useEffect(() => {
    if (servicesPicked.length > 0) {
      setTempObj((prevState) => ({ ...prevState, services: servicesPicked }));
      setTempData((prevState) => ({ ...prevState, services: servicesPicked }));
    }
  }, [servicesPicked]);

  const languageChange = (event) => {
    let tempLang = {
      hungarian: true,
      chinese: false,
      english: false,
      german: false,
      spanish: false,
      french: false,
      russian: false,
      romania: false,
    };

    const {
      target: { value },
    } = event;
    value.forEach((lang) => (tempLang[lang] = true));
    setTempObj((prevState) => ({ ...prevState, languages: tempLang }));
    setTempData((prevState) => ({ ...prevState, languages: tempLang }));

    setLanguagesPicked(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const setBankData = (event) => {
    let tempObj = {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      other: false,
      bankidk: false,
    };
    const {
      target: { value },
    } = event;
    value.forEach((opt) => (tempObj[opt] = true));
    setTempObj((prevState) => ({ ...prevState, bankingpref: tempObj }));
    setTempData((prevState) => ({ ...prevState, bankingpref: tempObj }));

    setChosenBank(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const setInvoData = (event) => {
    let tempObj = {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      other: false,
      invoicingidk: true,
    };
    const {
      target: { value },
    } = event;
    value.forEach((opt) => (tempObj[opt] = true));
    setTempObj((prevState) => ({ ...prevState, invoicingpref: tempObj }));
    setTempData((prevState) => ({ ...prevState, invoicingpref: tempObj }));

    setChosenInvo(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const setSoftwareData = (event) => {
    let tempObj = {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      opt9: false,
      opt10: false,
      opt11: false,
      opt12: false,
      opt13: false,
      opt14: false,
      opt15: false,
      opt16: false,
      opt17: false,
      opt18: false,
      opt19: false,
      opt20: false,
      other: false,
    };
    const {
      target: { value },
    } = event;
    value.forEach((opt) => (tempObj[opt] = true));
    setTempObj((prevState) => ({ ...prevState, softwarepref: tempObj }));
    setTempData((prevState) => ({ ...prevState, softwarepref: tempObj }));

    setChosenSoft(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const singleValueHandler = useCallback((event) => {
    setTempObj((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
    setTempData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

    setIsChanged(true);
  });

  const switchHandler = (e) => {
    console.log(e.target.checked, 'CHECKED');

    setTempObj((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }));
    setTempData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
    setIsChanged(true);
  };

  const messageHandler = useCallback(
    (event) => {
      setMessage(event.target.value);
    },
    [message, setMessage]
  );

  useEffect(() => {
    tempData && matchObjects(tempObj, tempData);
  }, [tempData]);

  const setPriceOnGrpChange = (event) => {
    if (tempData) {
      console.log(tempData, 'TEMPDATA IN GRPCHANGE');
      if (event.target.value === 'ev') {
        console.log('EV IN USEEFFECT');
        setTempObj((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[0][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[0][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[0][0].contMinPrice} - ${tempData.potential_providers[0][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[0][0].singleEndPrice,
        }));
        setTempData((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[0][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[0][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[0][0].contMinPrice} - ${tempData.potential_providers[0][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[0][0].singleEndPrice,
        }));
      } else if (event.target.value === 'office') {
        console.log('OFFICE IN USEEFFECT');
        setTempObj((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[1][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[1][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[1][0].contMinPrice} - ${tempData.potential_providers[1][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[1][0].singleEndPrice,
        }));
        setTempData((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[1][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[1][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[1][0].contMinPrice} - ${tempData.potential_providers[1][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[1][0].singleEndPrice,
        }));
      } else if (event.target.value === 'enterprise') {
        console.log('ENTERPRISE IN USEEFFECT');
        setTempObj((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[2][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[2][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[2][0].contMinPrice} - ${tempData.potential_providers[2][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[2][0].singleEndPrice,
        }));
        setTempData((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[2][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[2][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[2][0].contMinPrice} - ${tempData.potential_providers[2][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[2][0].singleEndPrice,
        }));
      }
    }
  };
  return (
    <div>
      {tempData ? (
        <div className="validationGrid">
          <div className="validationChild1">
            {tempData.isfakecherry ? (
              <p style={{ fontWeight: '700', fontSize: '20px', color: 'red' }}>Cherrypickről jött!</p>
            ) : tempData.selectedProvGrp !== '' &&
              tempData.selectedProvGrp !== null &&
              tempData.selectedProvGrp !== undefined ? (
              <p style={{ fontWeight: '700', fontSize: '20px', color: 'red' }}>ASPről jött TOP3!</p>
            ) : (
              <p style={{ fontWeight: '700', fontSize: '20px', color: 'red' }}>ASPről jött!</p>
            )}
            <p>
              BUPA-s keresés?: <b>{tempData.BupaID ? 'IGEN' : 'NEM'}</b>{' '}
              <span style={{ marginLeft: '2rem' }}>
                BUPA érdekli: nem{' '}
                <Switch
                  checked={tempObj.interestedInBupa}
                  value={tempObj.interestedInBupa}
                  onChange={(e) => switchHandler(e)}
                  name="interestedInBupa"
                />{' '}
                igen
              </span>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Ajánlatkérő neve: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="companyctcname"
                name="companyctcname"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.companyctcname}
                sx={{ fontSize: 14 }}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Ajánlatkérő e-mail: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="email"
                name="email"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.email}
                sx={{ fontSize: 14 }}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Ajánlatkérő telefon: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="companyPhone"
                name="companyPhone"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.companyPhone}
                sx={{ fontSize: 14 }}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Irányítószám: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="zip_code"
                name="zip_code"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.zip_code}
                sx={{ fontSize: 14 }}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Mikortól kéri: </span>
              <Select
                id="chosendate"
                name="chosendate"
                onChange={singleValueHandler}
                value={tempData.chosendate}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'asap'}>Minél hamarabb</MenuItem>
                <MenuItem value={'2weeks'}>1-2 hét</MenuItem>
                <MenuItem value={'2months'}>1-2 hónap</MenuItem>
                <MenuItem value={'6months'}>5-6 hónap</MenuItem>
              </Select>
            </p>
            {/* {tempData.selectedProvGrp !== '' && ( */}
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Kiválasztott csoport: </span>
              <Select
                id="selectedProvGrp"
                name="selectedProvGrp"
                onChange={(event) => {
                  singleValueHandler(event);
                  setPriceOnGrpChange(event);
                }}
                value={tempData.selectedProvGrp || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={''}>Nincs kiválasztva</MenuItem>
                <MenuItem value={'ev'}>EV</MenuItem>
                <MenuItem value={'office'}>Kis Iroda</MenuItem>
                <MenuItem value={'enterprise'}>Nagy Iroda</MenuItem>
              </Select>
            </p>
            {/* )} */}
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Megbízás: </span>
              <Select
                id="isCommission"
                name="isCommission"
                onChange={singleValueHandler}
                value={tempData.isCommission}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={true}>Igen</MenuItem>
                <MenuItem value={false}>Nem</MenuItem>
              </Select>
            </p>
            {!tempData.isfakecherry && (
              <>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Folyamatos ár: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    sx={{ fontSize: 14 }}
                    style={{ width: '300px' }}
                    variant="standard"
                    value={tempData.originalContPrice}
                  />
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Folyamatos ár(kedvezményes):{' '}
                  </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    sx={{ fontSize: 14 }}
                    style={{ width: '300px' }}
                    variant="standard"
                    value={tempObj.proposedContPrice}
                  />
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Egyszeri ár: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    sx={{ fontSize: 14 }}
                    style={{ width: '300px' }}
                    variant="standard"
                    value={tempData.originalSinglePrice}
                  />
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Kedvezménykód: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    sx={{ fontSize: 14 }}
                    style={{ width: '300px' }}
                    variant="standard"
                    value={tempData.discountCode}
                    onChange={(event) => {
                      setTempData((prevState) => ({ ...prevState, discountCode: event.target.value }));
                      setTempObj((prevState) => ({ ...prevState, discountCode: event.target.value }));
                    }}
                  />
                </p>
                <p>
                  <Button
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    sx={{ fontSize: 14 }}
                    style={{ width: '100%' }}
                    variant="standard"
                    disabled={tempData.discountCode === '' ? true : false}
                    onClick={async () => {
                      await couponCheck({
                        couponCode: tempData.discountCode,
                        flow: 'search',
                      });
                      console.log(discountObj, 'discountObj');
                      // if (
                      //   tempData.companytype === 'singleprimary' ||
                      //   tempData.companytype === 'singlesecondary' ||
                      //   tempData.companytype === 'kiva' ||
                      //   tempData.companytype === 'vszja'
                      // ) {
                      //   setTempObj((prevState) => ({
                      //     ...prevState,
                      //     proposedContPrice: parseInt(tempObj.originalContPrice) - parseInt(discountObj.discount),
                      //   }));
                      // } else {
                      //   setTempObj((prevState) => ({
                      //     ...prevState,
                      //     proposedContPrice: parseInt(tempObj.originalContPrice) - parseInt(discountObj.discountLarge),
                      //   }));
                      // }
                    }}
                  >
                    <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Kód check </span>
                  </Button>
                </p>
              </>
            )}
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Üzenet: </span>
              <TextareaAutosize
                inputProps={{ style: { fontSize: 14, padding: 2, border: 'none' } }}
                style={{ width: 'calc(80% - 200px)' }}
                variant="standard"
                value={tempData.reqMessage}
                sx={{ fontSize: 14 }}
                maxRows={2}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Új alapítású: </span>
              <Select
                id="newlyfounded"
                name="newlyfounded"
                onChange={singleValueHandler}
                value={tempData.newlyfounded}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={true}>Igen</MenuItem>
                <MenuItem value={false}>Nem</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Szolgáltatás:</span>
              <Select
                variant="standard"
                onChange={(event) => {
                  serviceChange(event);
                }}
                multiple
                value={servicesPicked}
                // renderValue={(selected) => selected.join(', ')}
                renderValue={(selected) => selected.map(service => services.find(s => s.value === service)?.label).join(', ')}
                style={{ width: '300px' }}
                sx={{ fontSize: 14 }}
              >
                {services.map((service) => (
                  <MenuItem key={service.value} value={service.value}>
                    <Checkbox checked={servicesPicked.indexOf(service.value) > -1} />
                    <ListItemText primary={service.label} />
                  </MenuItem>
                ))}
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Alkalmazottak: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="noofemployees"
                name="noofemployees"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.noofemployees}
                sx={{ fontSize: 14 }}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Éves árbevétel: </span>
              <Select
                id="yearlyrevenue"
                name="yearlyrevenue"
                onChange={singleValueHandler}
                value={tempData.yearlyrevenue}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={5}>0-5 millió</MenuItem>
                <MenuItem value={10}>5-12 millió</MenuItem>
                <MenuItem value={50}>12-50 millió</MenuItem>
                <MenuItem value={100}>50-100 millió</MenuItem>
                <MenuItem value={200}>100-200 millió</MenuItem>
                <MenuItem value={500}>200-500 millió</MenuItem>
                <MenuItem value={1999}>500-2000 millió</MenuItem>
                <MenuItem value={2000}>2000+ millió</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Bejövő számlák: </span>
              <Select
                id="incominginvoices"
                name="incominginvoices"
                onChange={singleValueHandler}
                value={tempData.incominginvoices}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={10}>1-10 között</MenuItem>
                <MenuItem value={20}>11-20 között</MenuItem>
                <MenuItem value={50}>21-50 között</MenuItem>
                <MenuItem value={100}>51-100 között</MenuItem>
                <MenuItem value={200}>101-200 között</MenuItem>
                <MenuItem value={500}>201-500 között</MenuItem>
                <MenuItem value={1000}>501-1000 között</MenuItem>
                <MenuItem value={2000}>1000 fölött</MenuItem>
                <MenuItem value={0}>Nem tudja</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>kimenő számlák: </span>
              <Select
                id="outgoinginvoices"
                name="outgoinginvoices"
                onChange={singleValueHandler}
                value={tempData.outgoinginvoices}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={10}>1-10 között</MenuItem>
                <MenuItem value={20}>11-20 között</MenuItem>
                <MenuItem value={50}>21-50 között</MenuItem>
                <MenuItem value={100}>51-100 között</MenuItem>
                <MenuItem value={200}>101-200 között</MenuItem>
                <MenuItem value={500}>201-500 között</MenuItem>
                <MenuItem value={1000}>501-1000 között</MenuItem>
                <MenuItem value={2000}>1000 fölött</MenuItem>
                <MenuItem value={0}>Nem tudja</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Iparág: </span>
              <Select
                id="industry"
                name="industry"
                onChange={singleValueHandler}
                value={tempData.industry}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'IT'}>Informatika</MenuItem>
                <MenuItem value={'construction'}>Építőipar</MenuItem>
                <MenuItem value={'etrade'}>E-kereskedelem</MenuItem>
                <MenuItem value={'trade'}>Kis-és nagyker</MenuItem>
                <MenuItem value={'art'}>Művészet</MenuItem>
                <MenuItem value={'education'}>Oktatás, Kutatás</MenuItem>
                <MenuItem value={'medicine'}>Egészségügy</MenuItem>
                <MenuItem value={'vehicle'}>Gépjármű Ipar</MenuItem>
                <MenuItem value={'manufacturing'}>Gyártás és Feldolgozó Ipar</MenuItem>
                <MenuItem value={'realestate'}>Ingatlankereskedelem</MenuItem>
                <MenuItem value={'agriculture'}>Mező-és erdőgazdálkodás, halászat</MenuItem>
                <MenuItem value={'finance'}>Pénzügy, biztosítás</MenuItem>
                <MenuItem value={'travel'}>Szállás, Vendéglátás</MenuItem>
                <MenuItem value={'startup'}>Start up</MenuItem>
                <MenuItem value={'logistics'}>Szállítás, raktározás</MenuItem>
                <MenuItem value={'courier'}>Futár</MenuItem>
                <MenuItem value={'beauty'}>Szépségipar</MenuItem>
                <MenuItem value={'otherservices'}>Egyéb</MenuItem>
                <MenuItem value={'lawyer'}>Ügyvédi iroda</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Cégforma: </span>
              <Select
                id="companytype"
                name="companytype"
                onChange={singleValueHandler}
                value={tempData.companytype}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'singleprimary'}>KATA</MenuItem>
                <MenuItem value={'kiva'}>EV átalány fő</MenuItem>
                <MenuItem value={'singlesecondary'}>EV átalány mellék</MenuItem>
                <MenuItem value={'vszja'}>EV VSZJA</MenuItem>
                <MenuItem value={'bt'}>Bt</MenuItem>
                <MenuItem value={'kft'}>Kft</MenuItem>
                <MenuItem value={'zrt'}>Zrt</MenuItem>
                <MenuItem value={'kkt'}>Kkt</MenuItem>
                <MenuItem value={'nyrt'}>Nyrt</MenuItem>
                <MenuItem value={'condo'}>Társasház</MenuItem>
                <MenuItem value={'citycouncil'}>Önkormányzat</MenuItem>
                <MenuItem value={'civil'}>Civil szervezet</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Adó forma: </span>
              <Select
                id="taxform"
                name="taxform"
                onChange={singleValueHandler}
                value={tempData.taxform}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'kiva'}>Kiva</MenuItem>
                <MenuItem value={'tao'}>Tao</MenuItem>
                <MenuItem value={'idk'}>Nem tudja</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Áfa alanyiság: </span>
              <Select
                id="vatFilter"
                name="vatFilter"
                onChange={singleValueHandler}
                value={tempData.vatFilter}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={true}>Igen</MenuItem>
                <MenuItem value={false}>Nem</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Áfa gyakoriság: </span>
              <Select
                id="vatPayment"
                name="vatPayment"
                onChange={singleValueHandler}
                value={tempData.vatPayment}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'monthly'}>Havi</MenuItem>
                <MenuItem value={'quarterly'}>Negyedéves</MenuItem>
                <MenuItem value={'yearly'}>Éves</MenuItem>
                <MenuItem value={'idk'}>Nem tudja</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Nyelvek: </span>
              <Select
                variant="standard"
                onChange={languageChange}
                multiple
                value={languagesPicked}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: '300px' }}
                sx={{ fontSize: 14 }}
              >
                {languages.map((language) => (
                  <MenuItem key={language} value={language}>
                    <Checkbox checked={languagesPicked.indexOf(language) > -1} />
                    <ListItemText primary={language} />
                  </MenuItem>
                ))}
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Külkereskedelem: </span>
              <Select
                id="foreign_trade"
                name="foreign_trade"
                onChange={singleValueHandler}
                value={tempData.foreign_trade}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={true}>Igen</MenuItem>
                <MenuItem value={false}>Nem</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Külkereskedelem irány: </span>
              <Select
                id="foreigntradeEU"
                name="foreigntradeEU"
                onChange={singleValueHandler}
                value={tempData.foreigntradeEU}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'inside'}>EU-n belül</MenuItem>
                <MenuItem value={'outside'}>EU-n kívűl</MenuItem>
                <MenuItem value={'both'}>Mindkettő</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Preferenciák (0-nem fontos, 3-fontos, 5-nagyon fontos):{' '}
              </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="preferences"
                name="preferences"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.preferences}
                sx={{ fontSize: 14 }}
                multiline
                maxRows={4}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Bank preferenciák: </span>
              <Select
                variant="standard"
                onChange={setBankData}
                multiple
                value={chosenBank}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: '300px' }}
                sx={{ fontSize: 14 }}
              >
                {bankPref.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    <Checkbox checked={chosenBank.indexOf(opt.value) > -1} />
                    <ListItemText primary={opt.label} />
                  </MenuItem>
                ))}
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Számlázó preferenciák: </span>
              <Select
                variant="standard"
                onChange={setInvoData}
                multiple
                value={chosenInvo}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: '300px' }}
                sx={{ fontSize: 14 }}
              >
                {invoPref.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    <Checkbox checked={chosenInvo.indexOf(opt.value) > -1} />
                    <ListItemText primary={opt.label} />
                  </MenuItem>
                ))}
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Könyvelő program preferenciák(ha NEM EV!):{' '}
              </span>
              <Select
                variant="standard"
                onChange={setSoftwareData}
                multiple
                value={chosenSoft}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: '300px' }}
                sx={{ fontSize: 14 }}
              >
                {softPref.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    <Checkbox checked={chosenSoft.indexOf(opt.value) > -1} />
                    <ListItemText primary={opt.label} />
                  </MenuItem>
                ))}
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Dolgoznál ezzel az ügyféllel: </span>
              <Select
                id="wouldProviderTakeit"
                name="wouldProviderTakeit"
                onChange={singleValueHandler}
                value={tempData.wouldProviderTakeit}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={true}>Igen</MenuItem>
                <MenuItem value={false}>Nem</MenuItem>
              </Select>
            </p>
            <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-evenly' }}>
              <Button
                onClick={() => {
                  updateOffer(tempObj, matchScore);
                  setIsChanged(true);
                }}
              >
                Frissítés
              </Button>
              {isChanged ? (
                <>
                  {' '}
                  <Button
                    style={{ color: 'red', border: '1px solid red' }}
                    onClick={() => {
                      updateRequestData(statusID, tempObj);
                      setIsChanged(false);
                    }}
                  >
                    Mentés
                  </Button>
                </>
              ) : (
                <></>
              )}
              <Button
                onClick={() => {
                  sendMail(tempObj);
                }}
              >
                E-mail küldése
              </Button>
            </div>
          </div>
          <div className="validationChild2">
            {/* <div style={{ width: '250px' }}>
              <p style={{ fontWeight: '700', fontSize: '18px', color: '#2065D1' }}>Ennyi könyvelőt találtunk: </p>
              <p style={{ fontSize: '24px', margin: '0 auto' }}>
                {tempData.potential_providers.length !== 0 ? tempData.potential_providers.length : 0}
              </p>
            </div> */}
            <div className="StatusDetailsLabel">
              <Dropdown
                label="CAF Státusz"
                id="cafstatus"
                list={cafStatusList}
                selValue={tempData.reqstatus.cafstatus || ''}
                onChange={(value) => setTempStatus({ ...tempStatus, cafstatus: value })}
              />
              <Dropdown
                label="ASP Státusz"
                id="aspstatus"
                list={aspStatusList}
                selValue={tempData.reqstatus.aspstatus || ''}
                onChange={(value) => setTempStatus({ ...tempStatus, aspstatus: value })}
              />
              <Dropdown
                label="REG Státusz"
                id="herstatus"
                list={herStatusList}
                selValue={tempData.reqstatus.herstatus || ''}
                onChange={(value) => setTempStatus({ ...tempStatus, herstatus: value })}
              />
              <div style={{ width: '150px', padding: '16px' }}>
                <Button
                  onClick={() => updateStatusStatus(tempData._id, tempStatus)}
                  style={{ float: 'right' }}
                  variant="contained"
                >
                  Státusz mentése
                </Button>
              </div>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Miért vált könyvelőt?</div>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="whychangingaccountant"
                name="whychangingaccountant"
                onChange={(e) => singleValueHandler(e)}
                style={{ width: '250px' }}
                variant="standard"
                value={tempObj.whychangingaccountant}
                sx={{ fontSize: 14 }}
                multiline
                maxRows={4}
              />
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Honnan hallot rólunk: </div>
              <Select
                id="howdidyoufindus"
                name="howdidyoufindus"
                onChange={singleValueHandler}
                value={tempData.howdidyoufindus}
                style={{ width: '250px' }}
                variant="standard"
              >
                <MenuItem value={'google'}>Google</MenuItem>
                <MenuItem value={'facebook'}>Facebook</MenuItem>
                <MenuItem value={'tiktok'}>Tik-tok</MenuItem>
                <MenuItem value={'friend'}>Ismerős</MenuItem>
                <MenuItem value={'bupa'}>Bupa</MenuItem>
                <MenuItem value={'szamlazzhu'}>Számlázz.hu</MenuItem>
                <MenuItem value={'freelancerblog'}>Freelancerblog</MenuItem>
                <MenuItem value={'glamour'}>Glamour</MenuItem>
                <MenuItem value={'otherweb'}>Más weboldal</MenuItem>
                <MenuItem value={'bookkeepieblog'}>Bookkeepie Blog</MenuItem>
                <MenuItem value={'other'}>Egyéb</MenuItem>
                <MenuItem value={'idk'}>Nem emlékszik</MenuItem>
              </Select>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Honnan hallott - részletes</div>
              <TextField
                multiline
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="howdidyoufindusinput"
                name="howdidyoufindusinput"
                onChange={(e) => singleValueHandler(e)}
                style={{ width: '250px' }}
                variant="standard"
                value={tempObj.howdidyoufindusinput}
                sx={{ fontSize: 14 }}
                maxRows={4}
              />
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Illetékes ügyintéző: </div>
              <Select
                id="responsibleagent"
                name="responsibleagent"
                onChange={singleValueHandler}
                value={tempData.responsibleagent}
                style={{ width: '250px' }}
                variant="standard"
              >
                <MenuItem value={''}>Nem hozzárendelt</MenuItem>
                <MenuItem value={'Andi'}>Andi</MenuItem>
                <MenuItem value={'Nina'}>Nina</MenuItem>
                <MenuItem value={'Orsi'}>Orsi</MenuItem>
                <MenuItem value={'Zsófi'}>Zsófi</MenuItem>
                <MenuItem value={'Dia'}>Dia</MenuItem>
                <MenuItem value={'Andris'}>Andris</MenuItem>
              </Select>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Hol keresett még:</div>
              <Select
                variant="standard"
                onChange={(event) => {
                  whereDidSearchChange(event);
                }}
                multiple
                value={placesSearched}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: '250px' }}
              >
                {whereDidSearch.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={placesSearched.indexOf(item.value) > -1} />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Mennyire volt nehéz?</div>
              <div style={{ width: '250px' }}>
                <Box style={{ width: '250px' }}>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={parseInt(tempData.howHardNr) || 1}
                    getAriaValueText={(value) => `${value}`}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={sliderMarks}
                    onChange={(e) => {
                      singleValueHandler(e);
                      //console.log(e.target.value, typeof e.target.value);
                    }}
                    min={1}
                    max={5}
                    id="howHardNr"
                    name="howHardNr"
                  />
                </Box>
              </div>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Mennyire volt nehéz?</div>
              <TextField
                multiline
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="howHardWasIt"
                name="howHardWasIt"
                onChange={(e) => singleValueHandler(e)}
                style={{ width: '250px' }}
                variant="standard"
                value={tempObj.howHardWasIt}
                sx={{ fontSize: 14 }}
                maxRows={4}
              />
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Min lehetne javitani?</div>
              <TextField
                multiline
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="whatCanWeImprove"
                name="whatCanWeImprove"
                onChange={(e) => singleValueHandler(e)}
                style={{ width: '250px' }}
                variant="standard"
                value={tempObj.whatCanWeImprove}
                sx={{ fontSize: 14 }}
                maxRows={4}
              />
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Miért nem minket választott: </div>
              <Select
                id="whyNotUs"
                name="whyNotUs"
                onChange={singleValueHandler}
                value={tempData.whyNotUs}
                style={{ width: '250px' }}
                variant="standard"
              >
                <MenuItem value={'Ismerőse könyveli végül'}>Ismerőse könyveli végül</MenuItem>
                <MenuItem value={'Külföldi, nem vállalkozhat per pill'}>Külföldi, nem vállalkozhat per pill</MenuItem>
                <MenuItem value={'telefonszám probléma, nem értük el (emailen sem'}>
                  telefonszám probléma, nem értük el
                </MenuItem>
                <MenuItem value={'mégsem vált könyvelőt'}>mégsem vált könyvelőt</MenuItem>
                <MenuItem value={'magának megoldja végül'}>magának megoldja végül</MenuItem>
                <MenuItem value={'nincs könyvelőnk aki az ügyfél által kért programban könyvelne'}>
                  nincs könyvelőnk aki az ügyfél által kért programban könyvelne
                </MenuItem>
                <MenuItem value={'nincs elég közel a könyvelő'}>nincs elég közel a könyvelő</MenuItem>
                <MenuItem value={'eltűnt (nem válaszolt többszöri megkeresésre sem)'}>
                  eltűnt (nem válaszolt többszöri megkeresésre sem)
                </MenuItem>
                <MenuItem value={'csak érdeklődött, egyelőre nem aktuális'}>
                  csak érdeklődött, egyelőre nem aktuális
                </MenuItem>
                <MenuItem value={'nem jó, hogy velünk szerződik és nem a könyvelővel'}>
                  nem jó, hogy velünk szerződik és nem a könyvelővel
                </MenuItem>
                <MenuItem value={'más hamarabb adott ajánlatot'}>más hamarabb adott ajánlatot</MenuItem>
                <MenuItem value={'túl drágák vagyunk'}>túl drágák vagyunk</MenuItem>
                <MenuItem value={'könyvelő nem volt szimpatikus'}>könyvelő nem volt szimpatikus</MenuItem>
                <MenuItem value={'Lezárva - egyéb'}>Lezárva - egyéb</MenuItem>
              </Select>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Miért nem minket egyéb?</div>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="whyNotUsText"
                name="whyNotUsText"
                onChange={(e) => singleValueHandler(e)}
                style={{ width: '250px' }}
                variant="standard"
                value={tempObj.whyNotUsText}
                sx={{ fontSize: 14 }}
                maxRows={4}
                multiline
              />
            </div>
          </div>
          <div className="validationChild3">
            <h3>Saját jegyzetek</h3>
            <div>
              <TextField
                multiline
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="messagelog"
                name="messagelog"
                onChange={messageHandler}
                style={{ width: '100%' }}
                maxRows={6}
                label={'Itt jegyzetelhetsz'}
                value={message}
              />
              <Button
                disabled={message.length === 0 ? true : false}
                onClick={() => {
                  updateStatusMessage(statusID, message);
                  setMessage('');
                }}
              >
                Küldés
              </Button>
            </div>
            {tempData.messagelog.toReversed().map((e, idx) => {
              return (
                <p key={idx}>
                  <span>{e.date}</span> <span>{e.message}</span>
                  <hr />
                </p>
              );
            })}
          </div>
          <div className="validationChild4">
            <h3>Telefonos jegyzetek</h3>
            <div>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="phonelog"
                name="phonelog"
                onChange={callHandler}
                style={{ width: '100%' }}
                multiline
                maxRows={6}
                label={'Itt jegyzetelhetsz'}
                value={callNote}
              />
              <Button
                disabled={callNote.length === 0 ? true : false}
                onClick={async () => {
                  await updateCallLog(statusID, callNote);
                  setCallNote('');
                }}
              >
                Küldés
              </Button>
              {tempData.phonelog.toReversed().map((e, idx) => {
                return (
                  <p key={idx}>
                    <span>{e.date}</span> <span>{e.message}</span>
                    <hr />
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: '16px' }}> Loading...</div>
      )}
    </div>
  );
};

export default StatusValidation;
