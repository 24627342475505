import React, { useEffect, useState, useContext } from 'react';
import MuiSwitch from 'src/components/Switch';
import {
  
  providerSpecialPreferencesList,
  providerEtradePreferencesList,
  providerVehiclePreferencesList
  } from '../profileHelper';
import { ProviderContext } from '../ProviderProfile';


const SpecRestrictions  = () => {
  const [data, setData] = useState(null);
  const { providerData, updateData } = useContext(ProviderContext);

  useEffect(() => {
    if (providerData) {
      setData({
        provider_special_industry_restrictions: providerData.provider_special_industry_restrictions,
        });
    }
  }, [providerData]);

 
  const handleCheck2 = async (id, value) => {
    let tempObj = data.provider_special_industry_restrictions;
    let newObj = tempObj.filter((i) => i.id === id)[0];
    //console.log(newObj, 'NYUOBJECT');
    newObj.on = value;
    setData({ ...data, provider_special_industry_restrictions: tempObj });
    await updateData('provider', { provider_special_industry_restrictions: tempObj });
  };
  const handleCheck3 = async (id, value) => {
    let tempObj = data.provider_special_industry_restrictions;
    let newObj = tempObj.filter((i) => i.id === id)[0];

    newObj.value = value;
    setData({ ...data, provider_special_industry_restrictions: tempObj });
    await updateData('provider', { provider_special_industry_restrictions: tempObj });
  };

  const getObject = (id, array) => {
    //console.log(array, 'ARRAY');
    // return array.filter((e) => e.id === id)[array.filter((e) => e.id === id).length - 1];
    return array.filter((e) => e.id === id)[0];
  };

  return (
    <div className="TabContent">
      {data ? (
        <div className="TabContentFlexRow">
          <div className="Padding12">
            <b>Speciális korlátozások</b>
            {providerSpecialPreferencesList.map((item, i) => {
              let fetchedData = getObject(item.id, data.provider_special_industry_restrictions);
              //console.log(fetchedData);
              return (
                <div className="Width550" style={{ display: 'flex', justifyContent: 'space-between' }} key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.id}`}
                    value={fetchedData.on}
                    //value={data[item.modelKey][item.on]}
                    onChange={handleCheck2}
                    style={{ width: '100%', justifyContent: 'space-between' }}
                  />
                  <input
                    type="number"
                    id={`${item.id}`}
                    value={fetchedData.value}
                    onChange={(e) => handleCheck3(e.target.id, e.target.value)}
                    style={{ width: '100px', marginLeft: '6px' }}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <b>Webshop korlátozások</b>
            {providerEtradePreferencesList.map((item, i) => {
              let fetchedData = getObject(item.id, data.provider_special_industry_restrictions);
              //console.log(fetchedData);
              return (
                <div className="Width550" style={{ display: 'flex', justifyContent: 'space-between' }} key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.id}`}
                    value={fetchedData.on}
                    //value={data[item.modelKey][item.on]}
                    onChange={handleCheck2}
                    style={{ width: '100%', justifyContent: 'space-between' }}
                  />
                  <input
                    type="number"
                    id={`${item.id}`}
                    value={fetchedData.value}
                    onChange={(e) => handleCheck3(e.target.id, e.target.value)}
                    style={{ width: '100px', marginLeft: '6px' }}
                  />
                </div>
              );
            })}
          
            <b>Gépjármű korlátozás</b>
            {providerVehiclePreferencesList.map((item, i) => {
              let fetchedData = getObject(item.id, data.provider_special_industry_restrictions);
              //console.log(fetchedData);
              return (
                <div className="Width550" style={{ display: 'flex', justifyContent: 'space-between' }} key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.id}`}
                    value={fetchedData.on}
                    //value={data[item.modelKey][item.on]}
                    onChange={handleCheck2}
                    style={{ width: '100%', justifyContent: 'space-between' }}
                  />
                  <input
                    type="number"
                    id={`${item.id}`}
                    value={fetchedData.value}
                    onChange={(e) => handleCheck3(e.target.id, e.target.value)}
                    style={{ width: '100px', marginLeft: '6px' }}
                  />
                </div>
              );
            })}
          </div>
          
          
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default SpecRestrictions ;
